import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { ISeoProps } from './Seo.types';

/*
  TODO
  - for a complete implementation please take
    a look here: https://www.gatsbyjs.com/docs/add-seo-component
*/

const SEO = ({
  canonical,
  description,
  hreflang,
  ogImageUrl,
  ogImageWidth,
  ogImageHeight,
  schemaOrg,
  title,
}: ISeoProps): JSX.Element => {
  const { site } = useStaticQuery(query);
  const { defaultTitle, titleTemplate, defaultDescription, siteUrl } =
    site.siteMetadata;
  const seo = {
    canonical,
    description: description || defaultDescription,
    hreflang,
    ogImageUrl,
    ogImageWidth,
    ogImageHeight,
    title: title || defaultTitle,
    url: siteUrl,
    schemaOrg,
  };

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.url && seo.canonical && (
        <link rel="canonical" href={`${seo.url}${canonical}`} />
      )}
      {seo.url && seo.canonical && seo.hreflang && (
        <link
          rel="alternate"
          href={`${seo.url}${canonical}`}
          hrefLang={seo.hreflang}
        />
      )}
      {seo.ogImageUrl && (
        <meta property="og:image:url" content={seo.ogImageUrl} />
      )}
      {seo.ogImageUrl && (
        <meta property="og:image:alt" content={seo.description} />
      )}
      {seo.ogImageWidth && (
        <meta property="og:image:width" content={seo.ogImageWidth} />
      )}
      {seo.ogImageHeight && (
        <meta property="og:image:height" content={seo.ogImageHeight} />
      )}
      <link
        rel="icon"
        href={`${process.env.GATSBY_CDN_URL}/icons/favicon.ico`}
        sizes="any"
      />
      <link
        rel="icon"
        href={`${process.env.GATSBY_CDN_URL}/icons/icon.svg`}
        type="image/svg+xml"
      />
      <link
        rel="apple-touch-icon"
        href={`${process.env.GATSBY_CDN_URL}/icons/apple-touch-icon.png`}
      />
      {seo.schemaOrg && (
        <script type="application/ld+json">{seo.schemaOrg}</script>
      )}
    </Helmet>
  );
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
      }
    }
  }
`;

export default SEO;
