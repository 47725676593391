import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { Base64 } from 'js-base64';

const axios = Axios.create();

export const httpGet = (req: AxiosRequestConfig): AxiosPromise => {
  const { url = '' } = req;
  return axios.get(url);
};
export const httpPost = (req: AxiosRequestConfig): AxiosPromise => {
  const { url = '', data } = req;
  return axios.post(url, data);
};
export const httpPut = (req: AxiosRequestConfig): AxiosPromise => {
  const { url = '', data } = req;
  return axios.put(url, data);
};
export const httpDelete = (req: AxiosRequestConfig): AxiosPromise => {
  const { url = '' } = req;
  return axios.delete(url);
};

export type { AxiosError as HttpError } from 'axios';

export const addAuthInterceptor = (apiToken: string): void => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${Base64.decode(apiToken)}`,
  };
};

export const ejectAuthInterceptor = (): void => {
  axios.defaults.headers.common = undefined;
};

export const getCurrentApiToken = (): string =>
  Base64.encode(axios.defaults.headers.common.Authorization.split(' ')[1]);
